import { Autocomplete, Grid, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";
import * as Yup from "yup";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import { FcSelectField, FcTextField, SubmitBtnGroup } from "../../FormFields";

export default function PriceIncreasePointForm({ data = null, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const { data: bookings, loading: bookingLoading, onGet: onBookingGet } = useHttpGet("/api/booking", false);
  const { data: agreements, loading: agreementLoading, onGet: onAgreementGet } = useHttpGet("/api/agreement", false);
  const { data: userData } = useHttpGet("/api/user/list");
  const { onPost } = useHttpPost("/api/booking/commission/price-increase");

  const customerTypes = [
    { label: "New", value: true },
    { label: "Exists", value: false },
  ];
  const users = userData?.filter(user => user.department === "sales")
    ?.map(user => ({ label: user.name, value: user.uid })) ?? [];
  users.sort((a, b) => a.label.localeCompare(b.label));

  const debounceOnChange = useCallback(debounce(async value => {
    if (value.length > 0) {
      await onBookingGet({ bookingNo: value });
    }
  }, 500), []);

  const handleAgreementChange = (e, newValue) => {
    formik.setFieldValue("firstRentalAgreementId", newValue?.id);
    formik.setFieldValue("firstRentalAgreement", newValue);
  }

  const handleBookingChange = (e, newValue) => {
    formik.setFieldValue("bookingId", newValue?.id);
    formik.setFieldValue("booking", newValue);
    formik.setFieldValue("firstRentalAgreementId", "");
    formik.setFieldValue("firstRentalAgreement", null);
    formik.setFieldValue("originalRentalPrice", newValue?.originalRentalPrice);
    onAgreementGet({ bookingNo: newValue?.bookingNo });
  }

  const handleBookingInputChange = (e, newInputValue) => {
    formik.setFieldValue("bookingNo", newInputValue);
    debounceOnChange(newInputValue);
  }

  const formik = useFormik({
    initialValues: {
      isNewCustomer: data?.isNewCustomer ?? false,
      status: "pending",
      userId: data?.userId ?? "",
      username: data?.username ?? "",
      originalPriceIncreasePoint: data?.originalPriceIncreasePoint ?? 0,
      priceIncreasePoint: data?.priceIncreasePoint ?? 0,
      deposit: data?.booking?.deposit ?? 0,
      rentalPrice: data?.booking?.rentalPrice ?? 0,
      originalRentalPrice: data?.booking?.originalRentalPrice ?? 0,
      firstRentalAgreementId: data?.firstRentalAgreementId ?? "",
      firstRentalAgreement: data?.firstRentalAgreement,
      booking: data?.booking,
      bookingNo: data?.booking?.bookingNo ?? "",
      bookingId: data?.bookingId ?? "",
    },
    validationSchema: Yup.object({
      isNewCustomer: Yup.bool().required("Check customer is new or not!"),
      status: Yup.string().required("Status is required!"),
      userId: Yup.string().required("Sales responsible is required!"),
      username: Yup.string().required("Sales responsible is required!"),
      originalPriceIncreasePoint: Yup.number().required("Original price increase point is required!"),
      priceIncreasePoint: Yup.number().required("Price Increase point is required!"),
      deposit: Yup.number().required("Deposit is required!"),
      rentalPrice: Yup.number().required("Rental Price is required!"),
      originalRentalPrice: Yup.number().required("Original Rental Price is required!"),
      firstRentalAgreementId: Yup.string().required("First rental agreement is required!"),
      bookingId: Yup.string().required("Booking is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Price increase point added successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    const user = userData?.find(user => user.uid === formik.values.userId);
    if (user) formik.setFieldValue("username", user.name);
  }, [formik.values.userId]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="New / Exists" name="isNewCustomer" items={customerTypes} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="User" name="userId" items={users} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              value={formik.values.booking} onChange={handleBookingChange}
              inputValue={formik.values.bookingNo} onInputChange={handleBookingInputChange}
              options={bookings ?? []} loading={bookingLoading}
              getOptionLabel={option => option?.bookingNo}
              renderInput={params => <TextField {...params} label="Booking" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              value={formik.values.firstRentalAgreement} onChange={handleAgreementChange}
              options={agreements ?? []} loading={agreementLoading}
              getOptionLabel={option => option?.agreementNo}
              renderInput={params => <TextField {...params} label="First Rental Agreement" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Price Increase Point" name="priceIncreasePoint" type="number" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Rental Price" name="rentalPrice" type="number" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Deposit" name="deposit" type="number" />
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Add" onCancel={onClose} />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

PriceIncreasePointForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}