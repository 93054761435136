import {DialogContent} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogActions, DialogTitle, Stack} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import DataContext from "../../../store/DataContext";
import ShellCardDetails from "../../Details/ShellCard/ShellCardDetails";
import AssignShellCardForm from "../../Form/ShellCard/AssignShellCardForm";
import CancelCardForm from "../../Form/ShellCard/CancelCardForm";
import UpdatePinShellCardForm from "../../Form/ShellCard/UpdatePinShellCardForm";

const UpdatePinAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Update Pin Record</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <UpdatePinShellCardForm cardId={row?.id} pin={row?.pin ?? ""} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Pin</Button>
    </>
  );
}

UpdatePinAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const AssignAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Assign to User</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <AssignShellCardForm cardId={row.id} onClose={() => setOpen(false)} onReload={onReload}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Assign</Button>
    </>
  );
}

AssignAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const CancelAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Cancel Card</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <CancelCardForm row={row} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Cancel</Button>
    </>
  );
}

CancelAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ViewAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPost, loading } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/unassign`);
  const { onPost: onRequestPost, loading: requestLoading } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/pin/request`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPost({ card_id: row?.id });
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Card unassigned successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  const onRequest = async e => {
    e.preventDefault();
    try {
      await onRequestPost({ pan: row?.pan, expiryDate: dayjs(row?.expiryDate).format("YYYYMMDD") });
      dataCtx.setSnackbarConfig({ open: true, message: "PIN requested successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Card {row?.pan}</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ShellCardDetails card={row}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={onSubmit} variant="contained" disabled={row.assign == null}>
            Unassign
          </LoadingButton>
          <LoadingButton loading={requestLoading} onClick={onRequest} variant="contained" color="secondary">
            Request PIN
          </LoadingButton>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  const { onPost, loading } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/refresh`);

  const onCheck = async e => {
    e.preventDefault();
    const payload = {};
    if (row?.pan) payload.pan = row.pan;
    else payload.mainReference = row?.mainReference;
    await onPost(payload);
    await onReload();
  }

  return (
    <Stack direction="row" spacing={1}>
      <ViewAction row={row} onReload={onReload}/>
      <UpdatePinAction row={row} onReload={onReload}/>
      <AssignAction row={row} onReload={onReload}/>
      <CancelAction row={row} onReload={onReload}/>
      <LoadingButton variant="contained" color="secondary" loading={loading} onClick={onCheck}>
        Refresh
      </LoadingButton>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function ShellCardTable({ data, loading, onReload }) {
  const columns = [
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 450,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>
    },
    { field: "name", headerName: "Name", align: "center", headerAlign: "center", width: 200 },
    { field: "pan", headerName: "PAN", align: "center", headerAlign: "center", width: 200 },
    {
      field: "assign.email", headerName: "Assign Account", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => row?.assign?.email ?? "",
    },
    {
      field: "assign.fullName", headerName: "Customer", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => row?.assign?.fullName ?? "",
    },
    { field: "purchaseCategoryName", headerName: "Purchase Category Name", align: "center", headerAlign: "center", width: 200 },
    { field: "pin", headerName: "PIN", align: "center", headerAlign: "center", width: 100 },
    {
      field: "expiryDate", headerName: "Expiry Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.expiryDate).format("DD MMM YYYY"),
    },
    { field: "statusDescription", headerName: "Status", align: "center", headerAlign: "center", width: 150 },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  );
}

ShellCardTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
}