import React, { useState } from 'react';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  Radio, 
  FormControl,
  FormLabel, 
  RadioGroup, 
  FormControlLabel,
  Button,
  Box,
  Typography,
  Container,
  Snackbar,
  Alert,
  Divider
} from "@mui/material";
import { cloudFunctionPath } from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";

const SalePersonAvailability = () => {
  const [availability, setAvailability] = useState({
    morning: "",
    evening: ""
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const { loading, error, onPost } = useCloudFuncPost(
    `${cloudFunctionPath}/workshop/sale-person-availability/save`,
    false
  );

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  const showNotification = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const clearForm = () => {
    setAvailability({
      morning: "",
      evening: ""
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!availability.morning || !availability.evening) {
      showNotification('Please select availability for both shifts | 请选择两个时段的可用性', 'error');
      return;
    }

    try {
      const payload = {
        morningShift: availability.morning === 'yes',
        eveningShift: availability.evening === 'yes'
      };

      await onPost(payload);
      
      showNotification('Salesperson availability saved successfully | 销售人员可用性保存成功', 'success');
      clearForm();
      
    } catch (err) {
      showNotification('Failed to save availability. Please try again. | 保存失败，请重试', 'error');
    }
  };

  // Show error if present
  React.useEffect(() => {
    if (error) {
      showNotification(error.message || ' An error occurred | 发生错误', 'error');
    }
  }, [error]);

  const handleAvailabilityChange = (shift) => (event) => {
    setAvailability(prev => ({
      ...prev,
      [shift]: event.target.value
    }));
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Card>
        <CardHeader 
          title={
            <Typography variant="h5" component="h2">
              销售人员可用性 / Sale Person Availability
            </Typography>
          }
        />
        <CardContent>
          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px'
            }}
          >
            {/* Morning Shift Availability */}
            <FormControl component="fieldset" required>
              <FormLabel component="legend">
                Is salesperson available for morning shift? | 销售人员上午班次是否有空？
              </FormLabel>
              <RadioGroup
                value={availability.morning}
                onChange={handleAvailabilityChange('morning')}
                sx={{ mt: 1 }}
              >
                <FormControlLabel 
                  value="yes" 
                  control={<Radio />} 
                  label="Yes | 是" 
                />
                <FormControlLabel 
                  value="no" 
                  control={<Radio />} 
                  label="No | 否" 
                />
              </RadioGroup>
            </FormControl>

            <Divider />

            {/* Evening Shift Availability */}
            <FormControl component="fieldset" required>
              <FormLabel component="legend">
                Is salesperson available for evening shift? | 销售人员下午班次是否有空？
              </FormLabel>
              <RadioGroup
                value={availability.evening}
                onChange={handleAvailabilityChange('evening')}
                sx={{ mt: 1 }}
              >
                <FormControlLabel 
                  value="yes" 
                  control={<Radio />} 
                  label="Yes | 是" 
                />
                <FormControlLabel 
                  value="no" 
                  control={<Radio />} 
                  label="No | 否" 
                />
              </RadioGroup>
            </FormControl>

            {/* Submit Button */}
            <Button 
              type="submit"
              variant="contained"
              fullWidth
              disabled={!availability.morning || !availability.evening || loading}
              sx={{ mt: 2 }}
            >
              {loading ? 'Saving... | 保存中...' : 'Submit | 提交'}
            </Button>
          </Box>

          {/* Snackbar Notification */}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert 
              onClose={handleSnackbarClose} 
              severity={snackbar.severity}
              sx={{ width: '100%' }}
              elevation={6}
              variant="filled"
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SalePersonAvailability;