import { Stack, Typography, Grid, Box, TextField, Checkbox } from '@mui/material';
import PropTypes from "prop-types";
import React, { useContext, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import ShellTransactionTable from '../../../components/Table/ShellCard/ShellTransactionTable';
import DataContext from '../../../store/DataContext';
import { FcDateField, SubmitBtnGroup } from '../../../components/Form/FormFields';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

const FilterForm = ({ handleSearch }) => {
  const dataCtx = useContext(DataContext);

  const formik = useFormik({
    initialValues: {
      startDate: moment().add(-1, "month").startOf("day"),
      endDate: moment().endOf("day"),
    },
    validationSchema: Yup.object({
      startDate: Yup.date().required("Please fill in Start Date"),
      endDate: Yup.date().required("Please fill in End Date"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await handleSearch(values);
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="End Date"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Search" onCancel={formik.resetForm} cancelText="Clear"/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

FilterForm.propTypes = {
  handleSearch: PropTypes.func,
}

export default function ShellTransactionListingTab() {
  const [name, setName] = useState("");
  const [showDeposit, setShowDeposit] = useState(true);
  const [showPayment, setShowPayment] = useState(true);

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/shellLedger`, true, {
    startDate: moment().add(-1, "month").startOf("day"),
    endDate: moment().endOf("day"),
  });

  const handleSearch = async (values) => {
    await onGet({
      ...values,
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString(),
    });
  }

  const getData = (data) => {
    if (name) data = data.filter(row => row?.user?.fullName?.toLowerCase()?.includes(name.toLowerCase()) ?? false);
    if (!showDeposit) data = data.filter(row => row.amount < 0);
    if (!showPayment) data = data.filter(row => row.amount > 0);
    return data;
  }

  return (
    <>
      <Box mb={3}>
        <FilterForm handleSearch={handleSearch} />
      </Box>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Shell Transaction Listing</Typography>
      </Stack>
      <Grid container alignItems="center" spacing={3} mb={3}>
        <Grid item>
          <Typography variant="h5">Filters:</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            value={name}
            onChange={e => setName(e.target.value)}
            label="Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Checkbox checked={showDeposit} onClick={e => setShowDeposit(!showDeposit)}/>
          <b>Deposit</b>
          <Checkbox checked={showPayment} onClick={e => setShowPayment(!showPayment)}/>
          <b>Payment</b>
        </Grid>
      </Grid>
      <ShellTransactionTable data={getData(data)} />
    </>
  );
}