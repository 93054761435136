import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Box, Container, Tab} from "@mui/material";
import {useState} from "react";
import ShellCardAssignRecordTab from "./Tabs/ShellCardAssignRecordTab";
import ShellCardUserTab from "./Tabs/ShellCardUserTab";
import ShellCardListingTab from "./Tabs/ShellCardListingTab";
import ShellRefuelTransactionListingTab from "./Tabs/ShellRefuelTransactionListingTab";
import ShellTransactionListingTab from "./Tabs/ShellTransactionListingTab";
import ShellTransactionReportTab from "./Tabs/ShellTransactionReportTab";

export default function ShellCard() {
  const [tabValue, setTabValue] = useState("card");

  return (
    <Container maxWidth="xl">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Card" value="card" />
            <Tab label="Card Assign Record" value="card-assign-record" />
            <Tab label="User" value="user" />
            <Tab label="Transaction" value="transaction" />
            <Tab label="Monthly Report" value="transaction-report" />
            <Tab label="Refuel Transaction (Shell)" value="refuel-transaction" />
          </TabList>
        </Box>

        <TabPanel value="card">
          <ShellCardListingTab />
        </TabPanel>
        <TabPanel value="card-assign-record">
          <ShellCardAssignRecordTab />
        </TabPanel>
        <TabPanel value="user">
          <ShellCardUserTab />
        </TabPanel>
        <TabPanel value="transaction">
          <ShellTransactionListingTab />
        </TabPanel>
        <TabPanel value="transaction-report">
          <ShellTransactionReportTab />
        </TabPanel>
        <TabPanel value="refuel-transaction">
          <ShellRefuelTransactionListingTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
}