import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Typography, 
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Paper,
  Chip,
  IconButton,
  Grid,
  Box,
  ImageList,
  ImageListItem,
  Button,
  Stack
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownloadIcon from '@mui/icons-material/Download';

const TireServiceListing = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tireServices, setTireServices] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ROAD_RUNNER_TIRE_TRACKING_API}/Tire/services`)
      .then(response => response.json())
      .then(data => {
        setTireServices(data.data);
      })
      .catch(error => console.error('Error fetching tire services:', error));
  }, []);

  const getPositionLabel = (position) => {
    switch (position) {
      case 0:
        return 'Front Left';
      case 1:
        return 'Front Right';
      case 2:
        return 'Rear Left';
      case 3:
        return 'Rear Right';
      default:
        return position;
    }
  };
  const handleExport = async () => {
    if (!startDate || !endDate) {
      alert('Please select both start and end dates');
      return;
    }

    setIsExporting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ROAD_RUNNER_TIRE_TRACKING_API}/Export/vehicle-services`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            startDate: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(),
          }),
        }
      );

      if (!response.ok) throw new Error('Export failed');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Vehicle_Service_Report_${startDate}_to_${endDate}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Export failed:', error);
      alert('Failed to export data');
    } finally {
      setIsExporting(false);
    }
  };

  const searchFields = (service) => {
    const searchableFields = [
      service.carPlateNumber,
      service.tireRecordGroupId.toString(),
      service.adminUserName,
      ...service.tireGroup.map(tire => [
        tire.brand,
        tire.size,
        tire.replacementReason,
        tire.price.toString(),
        tire.installationMileage.toString(),
        tire.totalUsed.toString(),
        tire.installationDate.toString(),
      ]).flat()
    ];
    
    return searchableFields.some(field => 
      field?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // Reset expanded rows when changing page
    setExpandedRows({});
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // Reset expanded rows when changing rows per page
    setExpandedRows({});
  };

  const filteredServices = searchTerm
    ? tireServices.filter(searchFields)
    : tireServices;

  // Calculate pagination
  const paginatedServices = filteredServices.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const toggleRow = (groupId) => {
    setExpandedRows(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const getImageUrl = (filePath) => {
    return `${process.env.REACT_APP_ROAD_RUNNER_TIRE_TRACKING_API}/S3/view/${filePath}`;
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="h1">
            Tire Service Records
          </Typography>
        </div>

        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <TextField
            placeholder="Search in all fields..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            sx={{ minWidth: 300 }}
          />
          <TextField
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExport}
            disabled={isExporting || !startDate || !endDate}
          >
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
        </Stack>

        <TableContainer component={Paper} elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"/>
                <TableCell>Car Plate</TableCell>
                <TableCell>Record ID</TableCell>
                <TableCell>Installation Date</TableCell>
                <TableCell align="right"># of Tires</TableCell>
                <TableCell align="right">Total Cost</TableCell>
                <TableCell align="right">Alignment</TableCell>
                <TableCell align='right'>Company Name</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedServices.map((service) => {
                const isExpanded = expandedRows[service.tireRecordGroupId];
                const totalCost = service.tireGroup.reduce((sum, tire) => sum + tire.price, 0);
                const hasCurrentTires = service.tireGroup.some(tire => tire.isCurrentTire);
                
                return (
                  <React.Fragment key={service.tireRecordGroupId}>
                    <TableRow 
                      hover
                      onClick={() => toggleRow(service.tireRecordGroupId)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <IconButton size="small">
                          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {service.carPlateNumber}
                      </TableCell>
                      <TableCell>{service.tireRecordGroupId}</TableCell>
                      <TableCell>
                        {service.tireGroup[0]?.installationDate}
                      </TableCell>
                      <TableCell align="right">{service.tireGroup.length}</TableCell>
                      <TableCell align="right">${totalCost}</TableCell>
                      <TableCell align="right">
                        {service.alignmentPrice ? `$${service.alignmentPrice}` : '-'}
                      </TableCell>
                      <TableCell align='right'>{service.adminUserName}</TableCell>
                      <TableCell>
                        <Chip
                          label={hasCurrentTires ? 'Current' : 'Historical'}
                          color={hasCurrentTires ? 'success' : 'default'}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                    
                    {isExpanded && (
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                          <Box sx={{ margin: 1 }}>
                            <Paper sx={{ backgroundColor: '#f5f5f5', p: 2 }}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Brand</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Mileage</TableCell>
                                    <TableCell align="right">Total Used</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Replacement Reason</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {service.tireGroup.map((tire) => (
                                    <TableRow key={tire.id}>
                                      <TableCell>{getPositionLabel(tire.position)}</TableCell>
                                      <TableCell>{tire.brand}</TableCell>
                                      <TableCell>{tire.size}"</TableCell>
                                      <TableCell align="right">${tire.price}</TableCell>
                                      <TableCell align="right">
                                        {tire.installationMileage.toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tire.totalUsed.toLocaleString()}
                                      </TableCell>
                                      <TableCell>
                                        <Chip
                                          label={tire.isCurrentTire ? 'Current' : 'Replaced'}
                                          color={tire.isCurrentTire ? 'success' : 'default'}
                                          size="small"
                                        />
                                      </TableCell>
                                      <TableCell>{tire.replacementReason}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>

                              {service.files && service.files.length > 0 && (
                                <Box sx={{ mt: 2 }}>
                                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Service Images
                                  </Typography>
                                  <ImageList sx={{ width: '100%', maxHeight: 300 }} cols={4} rowHeight={200}>
                                    {service.files.map((file) => (
                                      <ImageListItem key={file.id}>
                                        <img
                                          src={getImageUrl(file.filePath)}
                                          alt={file.fileName}
                                          loading="lazy"
                                          style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                            borderRadius: '4px'
                                          }}
                                        />
                                      </ImageListItem>
                                    ))}
                                  </ImageList>
                                </Box>
                              )}
                            </Paper>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredServices.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </TableContainer>
      </div>
    </Container>
  );
};

export default TireServiceListing;