import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { cloudFunctionPath } from "../../../cloudFunctionApiConfig";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";

const FilterForm = ({ users, filter, handleFilterChange }) => {
  const userItems = users.map(user => ({ label: user.fullName, value: user.id }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <InputLabel>User</InputLabel>
          <Select
            label="User"
            value={filter.userId}
            onChange={e => handleFilterChange("user", e.target.value)}
          >
            {userItems?.map(({ label, value, disabled = false }, index) => (
              <MenuItem value={value} key={index} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              view="month"
              disableMaskedInput
              label="Date"
              inputFormat="MM-YYYY"
              onChange={(val) => handleFilterChange("date", val)}
              value={filter.date}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
}

FilterForm.propTypes = {
  users: PropTypes.array,
  filter: PropTypes.object,
  handleFilterChange: PropTypes.func,
}

export default function ShellTransactionReportTab() {
  const [filter, setFilter] = useState({
    user: "",
    date: dayjs(),
  });

  const { data } = useCloudFuncGet(`${cloudFunctionPath}/user/verified`);

  const handleFilterChange = (field, value) => {
    setFilter({ ...filter, [field]: value });
  }

  return (
    <>
      <Typography variant="h4" mb={3}>Monthly Report</Typography>
      <FilterForm users={data?.data ?? []} filter={filter} handleFilterChange={handleFilterChange} />
    </>
  );
}