import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Chip,
  Alert
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { format } from 'date-fns';
import { cloudFunctionPath } from "../../cloudFunctionApiConfig";
import useCloudFuncGet from "../../hooks/firebase/cloudFunction/useCloudFuncGet";

const SalePersonAvailabilityList = () => {
  // const [availabilityData, setAvailabilityData] = useState([]);
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const { data: availabilityData, loading, error, onGet} = useCloudFuncGet(
    `${cloudFunctionPath}/workshop/sale-person-availability/list`,
    false,
    {
      startDate,
      endDate
    }
  );

  console.log(availabilityData);

  const fetchAvailabilityData = async () => {
    try {
      await onGet({
      startDate,
      endDate
    });
    } catch (error) {
      console.log(error);
    }
  }

  // // Only fetch when component mounts or dates change
  useEffect(() => {
    fetchAvailabilityData();
  }, []);

  const getAvailabilityChip = useCallback((isAvailable) => (
    <Chip
      label={isAvailable ? "Available" : "Unavailable"}
      color={isAvailable ? "success" : "error"}
      size="small"
    />
  ), []);

  const handleDateChange = useCallback((type, { target: { value } }) => {
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  }, []);

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center">
            Loading...
          </TableCell>
        </TableRow>
      );
    }

    if (availabilityData.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center">
            No availability data found
          </TableCell>
        </TableRow>
      );
    }

    return availabilityData?.data.records.map((row) => (
      <TableRow key={`${row.date}-${row.updatedAt}`}>
        <TableCell>{format(new Date(row.date), 'dd MMM yyyy')}</TableCell>
        <TableCell align="center">
          {getAvailabilityChip(row.morningShift)}
        </TableCell>
        <TableCell align="center">
          {getAvailabilityChip(row.eveningShift)}
        </TableCell>
        <TableCell>
          {format(new Date(row.updatedAt), 'dd MMM yyyy HH:mm')}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Card>
      <CardHeader
        title="Salesperson Availability History"
        action={
          <IconButton onClick={fetchAvailabilityData} disabled={loading}>
            <RefreshIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <input
            type="date"
            value={startDate}
            onChange={(e) => handleDateChange('start', e)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '16px'
            }}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => handleDateChange('end', e)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '16px'
            }}
          />
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="center">Morning Shift</TableCell>
                <TableCell align="center">Evening Shift</TableCell>
                <TableCell>Last Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTableContent()}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default SalePersonAvailabilityList;