import {Button, Dialog, DialogTitle, DialogContent, Stack, Typography, Box} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PriceIncreasePointForm from "../../../../components/Form/Sales/OTR/PriceIncreasePointForm";
import PreviewPriceIncreasePointTable from "../../../../components/Table/OTR/PreviewPriceIncreasePointTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add Missing Contract</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <PriceIncreasePointForm onReload={onReload} onClose={() => setOpen(false)} />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

export default function PriceIncreasePoints({ user, date }) {
  const { data: previewPoints, onGet: onPreviewGet } = useHttpGet("/api/booking/commission/price-increase/preview", false);

  const onReload = () => {
    // onGet({ userId: user?.uid ?? "", startDate: dayjs(date).toISOString() });
    onPreviewGet({ userId: user?.uid ?? "", startDate: dayjs(date).toISOString() });
  }

  useEffect(() => {
    onReload();
  }, [user, date]);

  return (
    <>
      <Typography variant="h4" mb={3}>Preview Contracts</Typography>
      <PreviewPriceIncreasePointTable data={previewPoints} onReload={onReload} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={3}>
        <Typography variant="h4">Added Contracts</Typography>
        <AddAction onReload={onReload} />
      </Stack>
      {/* <PriceIncreasePoints /> */}
    </>
  );
}

PriceIncreasePoints.propTypes = {
  user: PropTypes.object,
  date: PropTypes.object,
}