import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Stack
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Create blinking animation
const blink = keyframes`
  0% { background-color: #fff; }
  50% { background-color: #ffcdd2; }
  100% { background-color: #fff; }
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableCell = styled(TableCell)({
  fontSize: '1.375rem !important', // 22px equivalent
  padding: '1rem !important',
});

const StyledTableHeadCell = styled(TableCell)({
  fontSize: '1.5rem !important', // 24px equivalent
  padding: '1rem !important',
  fontWeight: 'bold !important',
});

const BlinkingTableCell = styled(TableCell)(({ shouldBlink }) => ({
  animation: shouldBlink ? `${blink} 1s infinite` : 'none',
  fontSize: '1.375rem !important', // 22px equivalent
  padding: '1rem !important',
}));

const ITEMS_PER_PAGE = 3;
const AUTO_ROTATE_INTERVAL = 10000; // 10 seconds

const getPositionLabel = (position) => {
  switch (position) {
    case 0:
      return 'Front Left';
    case 1:
      return 'Front Right';
    case 2:
      return 'Rear Left';
    case 3:
      return 'Rear Right';
    default:
      return position;
  }
};


const TireRecordsTable = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const checkTireUsage = (tire) => {
    const usageLimit = tire.isBrandNew ? 25000 : 15000;
    return tire.totalUsed < usageLimit;
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ROAD_RUNNER_TIRE_TRACKING_API}/Tire/live`)
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then(data => {
        setServices(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  // Auto-rotation effect
  useEffect(() => {
    if (services.length <= ITEMS_PER_PAGE) return;

    const totalPages = Math.ceil(services.length / ITEMS_PER_PAGE);
    const rotationTimer = setInterval(() => {
      setCurrentPage(prevPage => (prevPage + 1) % totalPages);
    }, AUTO_ROTATE_INTERVAL);

    return () => clearInterval(rotationTimer);
  }, [services.length]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '400px', alignItems: 'center' }}>
        <Typography variant="h3" color="text.secondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '400px', alignItems: 'center' }}>
        <Typography variant="h3" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  const totalPages = Math.ceil(services.length / ITEMS_PER_PAGE);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const visibleServices = services.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handlePrevPage = () => {
    setCurrentPage(prev => (prev - 1 + totalPages) % totalPages);
  };

  const handleNextPage = () => {
    setCurrentPage(prev => (prev + 1) % totalPages);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h2" sx={{ fontSize: '2.625rem' }}> {/* 42px equivalent */}
          Tire Monitoring System
        </Typography>
        {services.length > ITEMS_PER_PAGE && (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={handlePrevPage} size="large">
              <ChevronLeftIcon sx={{ fontSize: '2.25rem' }} /> {/* 36px equivalent */}
            </IconButton>
            <Typography sx={{ fontSize: '1.5rem' }}> {/* 24px equivalent */}
              Page {currentPage + 1} of {totalPages}
            </Typography>
            <IconButton onClick={handleNextPage} size="large">
              <ChevronRightIcon sx={{ fontSize: '2.25rem' }} /> {/* 36px equivalent */}
            </IconButton>
          </Stack>
        )}
      </Box>

      {visibleServices.map((carData) => (
        <Box key={carData.carPlateNumber} sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ 
            mb: 2, 
            bgcolor: 'primary.main', 
            color: 'white', 
            p: 1.5,
            fontSize: '2.25rem' // 36px equivalent
          }}>
            Vehicle: {carData.carPlateNumber}
          </Typography>
          {carData.currentTires.length > 0 && (
            <TableContainer component={Paper}>
              <Typography variant="h4" sx={{ p: 2, fontSize: '2rem' }}> {/* 32px equivalent */}
                Current Tires
              </Typography>
              <Table size="medium" aria-label="tire records table">
                <TableHead>
                  <TableRow sx={{ bgcolor: 'grey.100' }}>
                    <StyledTableHeadCell align='center'>Position</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Brand</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Size</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Installation Date</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Installation Mileage</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Price</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Replacement Reason</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {carData.currentTires.map((tire) => {
                    return (
                      <StyledTableRow key={tire.id}>
                        <StyledTableCell align='center'>{getPositionLabel(tire.position)}</StyledTableCell>
                        <StyledTableCell align='center'>{tire.tireBrand}</StyledTableCell>
                        <StyledTableCell align='center'>{tire.size}</StyledTableCell>
                        <StyledTableCell align='center'>
                          {new Date(tire.installationDate).toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {tire.installationMileage.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">${tire.price}</StyledTableCell>
                        <StyledTableCell align='center'>{tire.replacementReason}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {carData.previousTires.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Typography variant="h4" sx={{ p: 2, fontSize: '2rem' }}> {/* 32px equivalent */}
                Previous Tires
              </Typography>
              <Table size="medium" aria-label="tire records table">
                <TableHead>
                  <TableRow sx={{ bgcolor: 'grey.100' }}>
                    <StyledTableHeadCell align='center'>Position</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Brand</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Size</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Installation Date</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Installation Mileage</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Total Used</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Price</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Replacement Reason</StyledTableHeadCell>
                    <StyledTableHeadCell align='center'>Status</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {carData.previousTires.map((tire) => {
                    const needsAttention = checkTireUsage(tire);
                    return (
                      <StyledTableRow key={tire.id}>
                        <StyledTableCell align='center'>{getPositionLabel(tire.position)}</StyledTableCell>
                        <StyledTableCell align='center'>{tire.tireBrand}</StyledTableCell>
                        <StyledTableCell align='center'>{tire.size}</StyledTableCell>
                        <StyledTableCell align='center'>
                          {new Date(tire.installationDate).toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {tire.installationMileage.toLocaleString()}
                        </StyledTableCell>
                        <BlinkingTableCell 
                          align="center"
                          shouldBlink={needsAttention}
                        >
                          {tire.totalUsed.toLocaleString()}
                        </BlinkingTableCell>
                        <StyledTableCell align="center">${tire.price}</StyledTableCell>
                        <StyledTableCell align='center'>{tire.replacementReason}</StyledTableCell>
                        <StyledTableCell sx={{ 
                          color: needsAttention ? 'error.main' : 'success.main',
                          fontWeight: 'bold',
                          fontSize: '1.375rem !important' // 22px equivalent
                        }}>
                          {needsAttention ? 'ATTENTION NEEDED' : 'OK'}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default TireRecordsTable;