import { Box } from "@material-ui/core";
import PageContainer from "../../../components/OperationLiveDashboard/PageContainer";
import ViewJobLive from "./Children/ViewJobLive";
import TireRecordsTable from "./Children/TireServiceListing";


export default function LiveDashboard() {
    return <PageContainer intervalMs={300000}>
        <ViewJobLive />
        <TireRecordsTable />
    </PageContainer>;
}