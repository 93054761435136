import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box,
  IconButton,
  Button,
  Paper,
  Fade,
  Stack
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon
} from '@mui/icons-material';

// Styled components
const PageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '64px', // Height of control panel
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  boxShadow: theme.shadows[2],
}));

const ControlPanel = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '64px',
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  zIndex: 1000,
}));

const PageIndicator = styled(Box)(({ theme, active }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
  cursor: 'pointer',
  transition: theme.transitions.create('background-color'),
}));

const ContentWrapper = styled(Box)({
  top: 0,
  flex: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const PageContainer = ({ children, intervalMs = 300000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const pages = React.Children.toArray(children);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentIndex((current) => (current + 1) % pages.length);
      }, intervalMs);

      return () => clearInterval(interval);
    }
  }, [pages.length, intervalMs, isPaused]);

  const handlePageSelect = (index) => {
    setCurrentIndex(index);
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        {/* Current page with fade transition */}
        <Fade in timeout={500}>
          <Box sx={{ 
            width: '100%', 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
            {pages[currentIndex]}
          </Box>
        </Fade>
      </ContentWrapper>

      {/* Control panel */}
      <ControlPanel elevation={3}>
        <Button
          variant="contained"
          startIcon={isPaused ? <PlayArrowIcon /> : <PauseIcon />}
          onClick={togglePause}
          size="small"
        >
          {isPaused ? 'Resume' : 'Pause'}
        </Button>

        <Stack direction="row" spacing={1}>
          {pages.map((_, index) => (
            <PageIndicator
              key={index}
              active={index === currentIndex}
              onClick={() => handlePageSelect(index)}
            />
          ))}
        </Stack>
      </ControlPanel>
    </PageWrapper>
  );
};

export default PageContainer;