import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  PDFDownloadLink,
  PDFViewer,
  Text,
  StyleSheet,
  View
} from '@react-pdf/renderer';
import FcLogo from '../assets/fc-logo.png';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

Font.register({ family: 'Cardelina', src: 'https://fonts.cdnfonts.com/s/91519/Cardelina.woff' });

const rowView = {
  borderTop: '1px solid #EEE',
  display: 'flex',
  flexDirection: 'row',
  padding: 4,
  textAlign: 'left'
};

const styles = StyleSheet.create({
  rowView,
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  }
});

let uen;
const gstRegNo = '201608540Z';
const coExpander = (co, docNo) => {
  switch (co) {
    case 'FC':
      uen = '201608540Z';
      return 'Fresh Cars Pte Ltd';
    case 'OC':
      uen = '53314768M';
      return 'Orange Cars';
    case 'RT':
      uen = '202008200H';
      return 'Renty Pte Ltd';
    case 'OE':
      uen = '53388547K';
      return 'Orange Express';
    case 'CP':
      uen = '202443639H';
      return 'Clear Path Rentals';
    default:
      window.alert(`Company does not exist for document ${docNo}.`);
    // errorHandler(
    //   'Company does not exist.',
    //   'FinanceDocument',
    //   Error(`Company does not exist: ${co}`)
    // );
  }
};

const docTotal = (data) =>
  data.data.map((el) => el.Amount).reduce((total, curr) => total + curr, 0);

const quix2 = (data) => {
  // Check for Orange Cars Invoices to show GST only for Invoices starting from 01 Apr 2024 onwards
  const showGstReg =
    (data.company === 'OC' &&
      moment(data.docDate).isAfter(moment(data.ocGstRegDate).subtract(1, 'days'))) ||
    data.company === 'FC';

  return (
    <Document>
      <Page
        scale={2}
        size="A4"
        style={{
          display: 'flex',
          fontFamily: 'Helvetica',
          fontSize: 12,
          lineHeight: 1.5,
          padding: 40
        }}
      >
        <View style={styles.section}>
          <View style={{ display: 'flex' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>
              {coExpander(data.company, data.docNo)}
            </Text>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>{`UEN: ${uen}`}</Text>
            {showGstReg && data.isGstReg && (
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>{`GST Reg No: ${gstRegNo}`}</Text>
            )}
            <Text>105 Kaki Bukit Avenue 1</Text>
            <Text>#03-03 Shun Li Industrial Park</Text>
            <Text>Singapore 415987</Text>
          </View>
          {data.isGstReg === 'FC' && (
            <View style={{ width: '15%', alignItems: 'flex-end' }}>
              <Image src={FcLogo} cache={false} />
            </View>
          )}
        </View>

        <View style={{ color: '#6495ED' }}>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 18 }}>
            {`${showGstReg && data.isGstReg && data.docType === 'Invoice' ? 'Tax ' : ''}${
              data.docType
            }`}
          </Text>
        </View>

        <View style={styles.section}>
          <View>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>To</Text>
            <Text>{data.entity}</Text>
            <Text>{data.mainData.address}</Text>
            <Text>Singapore {data.mainData.postalCode}</Text>
          </View>

          <View style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text>No.: {data.docNo}</Text>
            <Text>Booking No.: {data.bookingNo}</Text>
            <Text>Date: {moment(data.docDate).format('D MMM yyyy')}</Text>
            <Text>Due Date: {moment(data.docDate).add(3, 'd').format('D MMM yyyy')}</Text>
            <Text>Terms: {data.invoiceTerms}</Text>
          </View>
        </View>

        <View style={{ ...styles.rowView, backgroundColor: '#EEEEEE', lineHeight: 1 }}>
          {data.columns.map((c) => (
            <Text
              key={c.name}
              style={{
                fontFamily: 'Helvetica-Bold',
                width: `${c.length}%`
              }}
            >
              {c.name}
            </Text>
          ))}
        </View>
        {data.data.map((rowData, i) => (
          <View key={i} style={{ ...styles.rowView, paddingVertical: 10 }}>
            {data.columns.map((c, j) => (
              <Text key={j} style={{ width: `${c.length}%` }}>
                {(c.name === 'Rate' || c.name === 'Amount') && '$'}
                {c.name === 'Rate' || c.name === 'Amount'
                  ? parseFloat(rowData[c.name], 10).toFixed(2)
                  : rowData[c.name]}
              </Text>
            ))}
          </View>
        ))}

        <View
          style={{
            ...styles.rowView,
            backgroundColor: '#EEEEEE',
            lineHeight: 1,
            justifyContent: 'space-between'
          }}
        >
          <Text>Total</Text>
          <Text>${parseFloat(docTotal(data)).toFixed(2)}</Text>
        </View>
        {showGstReg && data.isGstReg && data.gstRate > 0 && (
          <View
            style={{
              ...styles.rowView,
              backgroundColor: '#EEEEEE',
              lineHeight: 1,
              justifyContent: 'space-between'
            }}
          >
            <Text>
              GST @ {data.gstRate}% (inclusive){'\n'}
            </Text>
            <Text>${(docTotal(data) * (data.gstRate / (100 + data.gstRate))).toFixed(2)}</Text>
          </View>
        )}
        <View
          style={{
            ...styles.rowView,
            backgroundColor: '#EEEEEE',
            lineHeight: 1,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <Text>Amount Due</Text>
          <Text>${parseFloat(docTotal(data)).toFixed(2)}</Text>
        </View>

        <View style={{ fontFamily: 'Helvetica-Bold' }}>
          <Text>Remarks</Text>
        </View>

        <View style={{ marginBottom: 20 }}>
          <Text>{data.mainData.remarks}</Text>
        </View>

        {/* If component is near the bottom of the page, wrap=false forces the entire component to be rendered on one page (new page) instead of breaking it up into two parts across two pages. */}
        <View style={styles.section} wrap={false}>
          <View style={{ flex: 2 }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Notes</Text>
            <Text>
              Adjustments will be made within 45 business days if there is any mistake in invoicing
              such as undercharging. Please use your invoice number as payment reference when making
              payment.
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <br />
          </View>
          <View style={{ flex: 2 }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Payment</Text>
            {data.company !== 'CP' && <Text>Please make payments via PayNow to UEN 201608540Z.</Text>}
          </View>
        </View>

        <View
          style={{
            flex: 1,
            display: 'flex',
            paddingBottom: 1
          }}
        />

        {/* <View style={styles.section}>
        <View style={{ borderTop: '1px solid black' }}>
          <Text>Salesperson: {data.paidBy}</Text>
        </View>

        <View style={{ borderTop: '1px solid black' }}>
          <Text>Hirer: {data.entity}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={{ borderTop: '1px solid black' }}>
          <Text>Accounts</Text>
        </View>
      </View> */}
      </Page>
    </Document>
  );
};

export const pdfBase64StringGenerator = async (data) => {
  const reader = new FileReader();
  reader.readAsDataURL(await pdf(quix2(data)).toBlob());
  return new Promise((resolve) => (reader.onloadend = () => resolve(reader.result.split(',')[1])));
};

export const FinanceDocument = (props) => {
  FinanceDocument.propTypes = {
    data: PropTypes.object
  };

  return (
    <>
      <PDFViewer height={window.innerHeight * 0.7} width="100%">
        {quix2(props.data)}
      </PDFViewer>
      <PDFDownloadLink document={quix2(props.data)} fileName={`${props.data.id}.pdf`}>
        {({ blob, url, loading, error }) => (
          <Button fullWidth variant="contained">
            {loading ? <CircularProgress /> : 'Download'}
          </Button>
        )}
      </PDFDownloadLink>
      <br />
    </>
  );
};
