import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogContent, DialogActions, DialogTitle, Stack, Box, Checkbox} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";
import useCloudFuncPut from "../../../hooks/firebase/cloudFunction/useCloudFuncPut";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import ShellUserDiscountForm from "../../Form/ShellCard/ShellUserDiscountForm";
import AppUserDiscountRateTable from "./AppUserDiscountRateTable";

const AddAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Rate Record</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ShellUserDiscountForm user={row} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ViewAction = ({ row }) => {
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/wallet/card/discount/user`, false);

  const enabledRoles = ["manager", "superadmin"];

  useEffect(() => {
    if (open) onGet({ userId: row.id });
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row?.username} discount rates</DialogTitle>
        <DialogContent>
          <AppUserDiscountRateTable data={data?.data} onReload={onGet}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
          {enabledRoles.includes(authCtx.userDept) && <AddAction row={row} onReload={onGet}/>}
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View Rates</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const VerifyAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onPut, loading } = useCloudFuncPut(`${cloudFunctionPath}/user/${row?.id}/verify`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "user verified successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <LoadingButton loading={loading} variant="contained" color="secondary" onClick={onSubmit}>
      {row?.isPersonalInfoVerified ? "Unverify" : "Verify"}
    </LoadingButton>
  );
}

VerifyAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {

  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <ViewAction row={row}/>
      <VerifyAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AppUserDiscountTable({ data, loading = false, onReload }) {
  const columns = [
    { field: 'username', headerName: 'Username', align: "center", headerAlign: "center", width: 200 },
    { field: 'fullName', headerName: 'Full Name', align: "center", headerAlign: "center", width: 200 },
    { field: 'email', headerName: 'Email', align: "center", headerAlign: "center", width: 300 },
    { field: 'phone', headerName: 'Phone', align: "center", headerAlign: "center", width: 150 },
    { field: 'identity', headerName: 'NRIC / FIN', align: "center", headerAlign: "center", width: 150 },
    {
      field: 'isPersonalInfoVerified', headerName: 'Verified', align: "center", headerAlign: "center", width: 100,
      renderCell: ({ row }) => <Checkbox checked={row.isPersonalInfoVerified}/>,
    },
    {
      field: '', headerName: 'Action', align: "center", headerAlign: "center", width: 250,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  );
}

AppUserDiscountTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
}