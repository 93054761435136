import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import DataContext from '../../../store/DataContext';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

const YearFilter = ({ onReload, setParentYear }) => {
  const [yearOptions, setYearOptions] = useState([]);
  const thisyear = moment().year();
  const [year, setYear] = useState(thisyear.toString());

  const onChange = async (e) => {
    e.preventDefault();
    setYear(e.target.value);
    setParentYear(e.target.value);
    await onReload({
      year: e.target.value
    });
  };

  useEffect(() => {
    const startyear = 2023;
    const options = [];
    for (let i = thisyear; i >= startyear; i--) {
      options.push(i.toString());
    }
    setYearOptions(options);
  }, []);
  return (
    <Grid container spacing={3} sx={{ marginBottom: 5 }}>
      <Grid item xs={2}>
        <FormControl variant="standard" sx={{ width: '100%', marginBottom: 1 }}>
          <InputLabel>Year</InputLabel>
          <Select label="Year" name="year" value={year} onChange={onChange}>
            {yearOptions?.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={9} />

      {/* <Grid item xs={1} sx={{ textAlign: "end" }}>
        <Button variant="contained" onClick={onFilter}>
          Filter
        </Button>
      </Grid> */}
    </Grid>
  );
};

YearFilter.propTypes = {
  onReload: PropTypes.func,
  setParentYear: PropTypes.func
};

const LeaveSummaryList = () => {
  const dataCtx = useContext(DataContext);

  const [filterModel, setFilterModel] = useState({ items: [] });
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/leave/allUserSummary`);
  const [year, setYear] = useState(new Date().getFullYear().toString());

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      align: 'center',
      headerAlign: 'center',
      width: 75,
      sortable: false,
      valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
    },
    {
      field: 'name',
      headerName: 'Staff Name',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false
    },
    {
      field: 'department',
      headerName: 'Department',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) =>
        value === 'superadmin' ? 'Technology' : dataCtx.departments[value]
    },
    {
      field: 'startDate',
      headerName: 'Joined Date',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD-MM-YYYY') : '')
    },
    {
      field: 'carryForwardLastYear',
      headerName: `Leave Carried Over (${Number(year) - 1})`,
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'curFinancialYearLeaveEntitlement',
      headerName: `Leave Entitlement (${year})`,
      align: 'center',
      headerAlign: 'center',
      width: 250
    },
    {
      field: 'leaveAccrued',
      headerName: 'Leave Accrued (2024)',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: ({ row }) => row.leaveAccrued - row.carryForwardLastYear,
    },
    {
      field: 'leaveApplied',
      headerName: 'Leave Taken',
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'leaveBalance',
      headerName: 'Leave Balance',
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'leaveToBeClear',
      headerName: 'Leave To Be Cleared',
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'mcApplied',
      headerName: 'Medical Leave Taken',
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'totalMcFee',
      headerName: 'Total Medical Fee',
      align: 'center',
      headerAlign: 'center',
      width: 200
    }
  ];

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User Leave Summary
        </Typography>
      </Stack>
      <YearFilter onReload={onGet} setParentYear={setYear} />
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        rows={data?.data ?? []}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        disableSelectionOnClick
        sx={{ height: window.innerHeight * 0.7 }}
      />
      <Typography mt={3}>Leave Balance = Leave Accrued + Leave Carried Over - Leave Taken</Typography>
    </Container>
  );
};

export default LeaveSummaryList;
